#app-player{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    svg{
        
    }
}

.modal-config-player{
    top: 100px;
}